import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Button,DecodeBase64,Loader} from "."; 
import { LikeIcon,RightIcon } from "../Utilies/ImageExports";
import { API_SWIP_URLS } from "../Shared/Constant"; 
import { POST } from "../Shared/Axios"; 
export const Modal = ({ 
  text,
  type,
  title,
  subTitle,
  sub,
  congres_button,
  quizEnd,
}) => {
  const navigate = useNavigate();
  const [buyMore, setBuyMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("")
  const uid = window.localStorage.getItem('user-msisdn')
  const getLang = window.localStorage.getItem("lang")

  const onHandlePlayCongress = () => {
    navigate("/swipe-cards");
  };

  useEffect(() => {
    if (type === "buypopup") {
      setBuyMore(true)
    }
  }, [])

  const onHandlePlay = () => {
    setBuyMore(false);
    setIsLoading(true);
    const data = {
      operatorId: "orange_egypt",
      questions: true,
      userId: uid,
    };
    POST(API_SWIP_URLS.Questions, data)
      .then((res) => {
        setQuestion(res);
        navigate("/swipe-cards")
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (quizEnd?.status === "FAILURE") {
      // If the quiz end status is a failure, navigate to the buy page if there are packs, else to the info page.
      if (quizEnd.packs.length) {
        navigate("/buy", { state: { questions: quizEnd } });
      } else {
        navigate("/info");
      }
    } else if (quizEnd?.status === "SUCCESS") {
      // Otherwise, navigate to the info page.
      navigate("/swipe-cards");
    }
  }, [quizEnd]);

  const inputStyle = {
    width: "50px", // override the default width of the input elements
    height: "50px",
    margin: "10px",
    fontSize: "24px",
    fontWeight: 700,
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    outline: "none",
    textAlign: "center",
  };

  return (
    <div className="main">
      {isLoading ? <Loader /> : ""}
      {type === "modal" && (
        <div className=" Modal">
          <div className="modal_content">
            <p>{text}</p>
            <br></br>
            <div className="button-container">
              <Button name="Cancel" handleClick={() => navigate("/")} />
              <Button name="Yes" handleClick={() => navigate("/")} />
            </div>
          </div>
        </div>
      )}

      {type === "congress" && (
        <div className="Modal flex flex-col justify-center items-center ">
          <div className="modal_fade  bg-white rounded-xl m-4 p-4 max-w-[480px] flex flex-col justify-center items-center">
            <img src={RightIcon} alt="right-icon" width="80" />
            {console.log(title, "test")}
            <h2 className={`text-2xl text-center font-bold mt-2 ${getLang === "ar" ? "arabic-lang" : ""}`}>
              {title ? DecodeBase64(title) : ""}
            </h2>
            <p className={`mt-2 text-center ${getLang === "ar" ? "arabic-lang" : ""}`}>
              {subTitle ? DecodeBase64(subTitle) : ""}
            </p>
            <div className={`text-center mt-2 ${getLang === "ar" ? "arabic-lang" : ""}`}>
              {sub ? DecodeBase64(sub) : ""}
            </div>
            <div className="flex ">
              <Button
                name={congres_button ? DecodeBase64(congres_button) : "العب الان"}
                handleClick={onHandlePlayCongress}
                className={`button-theme p-2 cursor-pointer bg-[#ffde18] text-yellow-900 font-bold rounded-3xl mt-4 ${getLang === "ar" ? "arabic-lang" : ""}`}
              />
            </div>
          </div>
        </div>
      )}

      {buyMore && (
        <div className="Modal  animate-fade flex z-30 flex-col justify-center items-center ">
          <div className="modal_fade bg-white max-w-[480px] rounded-xl p-4 m-4 flex flex-col justify-center items-center">
            <img src={LikeIcon} alt="right-icon" width="80" />
            <h2 className={`text-2xl text-center font-bold mt-2 text-2xl text-green-600 ${getLang === "ar" ? "arabic-lang" : ""}`}>
              {getLang === "en" && "Congratulations!"}
              {getLang === "fr" && "Toutes nos félicitations!"}
              {getLang === "ar" && "تهانينا!"}
            </h2>
            <p className={`my-2 text-center font-semibold ${getLang === "ar" ? "arabic-lang" : ""}`}>
              {getLang === "en" && "You have successfully unlocked the next set of questions"}
              {getLang === "fr" && "Vous avez réussi à déverrouiller la prochaine série de questions"}
              {getLang === "ar" && "لقد نجحت في فتح مجموعة الأسئلة التالية"}
            </p>
            <div className="flex flex-col">
              <Button
                name={getLang === "fr" ? " Joue Maintenant " : getLang === "ar" ? " العب الان " : "Play Now"}
                handleClick={onHandlePlay}
                className={`button-theme pt-2 pl-4 pr-4 pb-2 text-yellow-900 cursor-pointer bg-[#ffde18]  font-bold  rounded-3xl mt-4 ${getLang === "ar" ? "arabic-lang" : ""}`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
