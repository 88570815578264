import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Header } from "../";
import "./subscribe.scss"

function Subscribe() {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate();
  const OnHandleUnsubscribe = () => {
    setModal(true)
  }
  return (
    <div className="main">
      <Header />
      {modal ? <Modal text={"Are you sure to unsubscribe? Click Yes to proceed with unsubscription."} type="modal" /> : ""}
      <div className="card">
        <p>By unsubscribing you will lose all your accumulated points. Please click
          Unsubscribe to confirm.</p>
        <Button name="Unsubscribe" className={"Unsubscribe-btn"} handleClick={OnHandleUnsubscribe} />

        <div className="home-btn" onClick={() => navigate("/")}>Home</div>
      </div>
    </div>
  );
}

export default Subscribe;
