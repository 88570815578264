import React from "react";
import { Header } from "../../Components"; // Assuming this is your custom Header component
import { useNavigate } from "react-router-dom";
import Congress from "../../Assets/Images/congratulations_tick_icon.png";
import Sorry from "../../Assets/Images/sorry_low_balance_icon.png";

function Congratulations() {
  const navigate = useNavigate();
  
  const checkSub = localStorage.getItem("checkSub");
  console.log("checksub",checkSub)
  const getLang="ar"
  const handleNext = () => {
    window.localStorage.removeItem("checkSub")
    navigate("/home");
    console.log("Go to next step");
    // Implement your navigation logic here
  };

  return (
    <div className="main h-screen">
      <Header text="Congs" />
      <div className="success-container text-center p-6">
        <div className="checkmark-circle mx-auto mb-6">
          {/* Insert checkmark icon here */}
          {checkSub === "true" ? (
            <img src={Congress} alt="congo" />
          ) : (
            <img src={Sorry} alt="sorry" />
          )}
        </div>
        <h1 className={`text-3xl font-bold mb-2 text-white ${getLang==="ar"?"arabic-lang":""}`}>
          {checkSub === "true" ? "مبروك" : "عذرا"}
        </h1>
        <p className={`mb-6 text-white ${getLang==="ar"?"arabic-lang":""}`}>
          {checkSub === "true"
            ? " أنت مشترك الآن في خدمة العب و اكسب لمدة يوم"
            : "لم يتم الاشتراك لان  الرصيد غير كاف!"}
        </p>
        <p className={`mb-6 text-yellow-700 ${getLang==="ar"?"arabic-lang":""}`}>
          {checkSub === "true" ? "" : "الرجاء إعادة الشحن والمحاولة مرة أخرى" }
        </p>
        {checkSub === "true" && (
         <button
         className={`confirm-button ${getLang==="ar"?"arabic-lang":""}`}
         onClick={handleNext}
       >
            التالي
          </button>
          
        )}
      </div>
    </div>
  );
}

export default Congratulations;
