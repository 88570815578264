import React, { useState } from "react";
import { swipeLogo } from "../Utilies/ImageExports";
import { API_SWIP_URLS } from "../Shared/Constant";
import { POST } from "../Shared/Axios";
import Loader from "../Components/Loader";
import Terms from "../Components/TermCondition";

function SelectPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const onHandleSelectLange = (e) => {
    e.target.textContent === "English" &&
      window.localStorage.setItem("lang", "en");
    e.target.textContent === "French" &&
      window.localStorage.setItem("lang", "fr");
    e.target.textContent === "Arabic" &&
      window.localStorage.setItem("lang", "ar");
    setTerms(true);
    getHomeScreenData();
  };
  const getHomeScreenData = () => {
    const data = { operatorId: "orange_egypt" };
    setIsLoading(true);
    POST(API_SWIP_URLS.Config, data)
      .then((res) => {
        // setSwipData(res.launchscreen);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="main home h-screen relative flex flex-col justify-between p-2 pb-40">
      {isLoading ? <Loader /> : ""}
      <div className="swip-logo">
        <img src={swipeLogo} alt="logo" width="150" />
      </div>

      <div
        className="flex flex-col justify-center text-justify items-center p-4  "
      >
        <h2 className=" flex text-lg justify-center text-white font-bold mb-4 items-center pb-4">
          Please select a language
        </h2>
        <div className="flex flex-row" >
          <div
            className="bg-gradient-to-r from-purple-300 to-pink-300 rounded-full  pb-2 pt-2 pl-4 pr-4 m-2 hover:bg-gradient-to-r from-purple-300 to-pink-300 cursor-pointer"
            value="en"
            onClick={(e) => onHandleSelectLange(e)}
          >
            English
          </div>
          <div
            className="bg-gradient-to-r from-purple-300 to-pink-300 rounded-full  pb-2 pt-2 pl-4 pr-4 m-2 hover:bg-gradient-to-r from-purple-300 to-pink-300 cursor-pointer"
            value="fr"
            onClick={(e) => onHandleSelectLange(e)}
          >
            French
          </div>
          <div
            className="bg-gradient-to-r from-purple-300 to-pink-300 rounded-full  pb-2 pt-2 pl-4 pr-4 m-2 hover:bg-gradient-to-r from-purple-300 to-pink-300 cursor-pointer"
            value="ar"
            onClick={(e) => onHandleSelectLange(e)}
          >
            Arabic
          </div>
        </div>
      </div>
      {terms && <Terms />}
    </div>
  );
}

export default SelectPage;
