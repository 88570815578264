import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DecodeBase64, Header, Loader, Modal } from "../Components";
import { API_SWIP_URLS } from "../Shared/Constant";
import { LikeIcon } from "../Utilies/ImageExports";
import useApiCall from "../Utilies/useApiCall";

function Results() {
  const location = useLocation();
  const { state } = location;
  const getLang = window.localStorage.getItem("lang");
  const [buyMore, setBuyMore] = useState(false);
  const navigate = useNavigate();
  const uid = window.localStorage.getItem('user-msisdn')
  const wap=localStorage.getItem("wap")
  const [questions, questionApiCall, questionLoading] = useApiCall(
    API_SWIP_URLS.Questions,
    { operatorId: "orange_egypt", questions: true, userId: uid }
  );
  const [buy, apiCall, loading] = useApiCall(API_SWIP_URLS.Buy, {
    operatorId: "orange_egypt",
    packId: "dailytopup",
    userId: uid,
  });

  useEffect(() => {
    if (buy) {
      setBuyMore(true);
    }
    if (questions) {
     navigate("/swipe-cards");
      if (questions.status === "SUCCESS" && questions.questions) {
        navigate("/swipe-cards");
      }
      if (questions.status === "FAILURE") {
        if (questions?.packs && questions?.packs[0]?.packType === "TOPUP") {
          navigate("/buy", { state: { questions: questions } });
        }else if(questions?.packs && questions?.packs[0]?.packType ===  "SUBSCRIPTION"){
         // window.location.replace(wap)
         localStorage.removeItem("user-msisdn")
         navigate("/login")
        }
         else {
          navigate("/info");
        }
      }
    }
  }, [buy, questions]);

  const onHandleClick = () => {
   navigate("/home")
   // apiCall();
  };

  const onHandlePlay = () => {
    console.log("clicked")
  //  navigate("/leaderboard")
   questionApiCall();
  // apiCall();
  };
  
  return (
    <div className="main h-screen" style={{backgroundColor:"#4b0083", width:"100%"}}>
      <Header text={"result"}/>
      {(loading || questionLoading) && <Loader />}
      <div className=" mt-1 text-white">
        <div
          className={`flex flex-col items-center justify-center ${getLang === "ar" ? "arabic-lang" : ""
            }`}
        >
          <img
            src={LikeIcon}
            alt="right-icon"
            width="80"
            className="mt-4 mb-2"
          />
          <h2
            className={`text-2xl text-center font-bold mt-2 text-3xl text-white ${getLang === "ar" ? "arabic-lang" : ""
              }`}
          >
            {state.propsData.quizEnd
              ? DecodeBase64(state?.propsData?.quizEnd?.greet?.title)
              : ""}
          </h2>
         
          <div className="rounded-full  bg-white h-24 w-24 mb-2 mt-3 flex flex-col justify-center items-center">
            <div
              className={`text-center p-6 font-bold text-3xl text-[#4b0083] ${getLang === "ar" ? "arabic-lang" : ""
                }`}
            >
              {state.propsData.quizEnd
                ? state.propsData.quizEnd.correctAnswers
                : ""}
            </div>
            
          </div>
          {/* <p
            className={`mt-2 text-center ${getLang === "ar" ? "arabic-lang" : ""
              }`}
          >
            {state.propsData.quizEnd
              ? DecodeBase64(state?.propsData?.quizEnd?.greet?.subtitle)
              : ""}
          </p> */}
          <div
            className={`text-center font-bold ${getLang === "ar" ? "arabic-lang" : ""
              }`}
          >
            {state.propsData.quizEnd
              ? DecodeBase64(state?.propsData?.quizEnd?.greet?.correctText)
              : ""}
          </div>
          {/* <div
            className={`text-center mt-2 ${getLang === "ar" ? "arabic-lang" : ""
              }`}
          >
            {state.propsData.quizEnd
              ? DecodeBase64(state?.propsData?.quizEnd?.greet?.next)
              : ""}
          </div> */}
          {state?.propsData?.quizEnd?.packs?.length ? (
            <div className="w-full h-full p-1   m-2">
              <div
                className={`p-2 text-center rounded-3xl bg-[#9876ff] ${getLang === "ar" ? "arabic-lang" : ""
                  }`}
              >
                {state.propsData.quizEnd?.packs &&
                  DecodeBase64(state?.propsData?.quizEnd?.packs[0]?.packName)}
              </div>
              <div className="flex justify-center mb-3">
                {/* <Button
                  name={
                    state.propsData.quizEnd?.packs &&
                    DecodeBase64(state?.propsData?.quizEnd?.packs[0]?.buttonText)
                  }
                  className={`button-theme bg-[#9876ff] cursor-pointer  px-4 py-3 text-white font-bold rounded-3xl my-4 ${getLang === "ar" ? "arabic-lang" : ""
                    }`}
                  handleClick={onHandleClick}
                /> */}
              </div>
            </div>
          ) : (
            <>
            <div className="flex justify-center mt-3 mb-2">  {state.propsData.quizEnd &&
                DecodeBase64(state?.propsData?.quizEnd?.greet?.next)}</div>
            <div
              // onClick={onHandlePlay}
              className={`button-theme  cursor-pointer bg-[#9876ff] pr-8 pl-8 pt-2 text-[#4b0083] pb-2 font-bold rounded-3xl my-4  ${getLang === "ar" ? "arabic-lang" : ""
                }`}
            >
              {state.propsData.quizEnd &&
                DecodeBase64(state?.propsData?.quizEnd?.greet?.nextButton)}
            </div>
            </>
          )}
         <Button name={state?.propsData?.quizEnd?.packs?.length?"العب":"الصفحة الرئيسية"} className={"button-theme bg-[#ffde18] px-4 py-2 mt-2 rounded-full text-yellow-900 font-bold"} handleClick={onHandlePlay}/>
        </div>
        {buyMore ? <Modal type="buypopup" /> : null}
        <div className="text-center text-yellow-600 font-bold mt-3 cursor-pointer" onClick={()=>{navigate("/terms-conditions")}}>الشروط والأحكام</div>
      </div>
    </div>
  );
}

export default Results;
