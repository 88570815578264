import React from "react";
import "./term.scss";
import { Header } from "..";

const TermNCondition = () => {
  // const getLang = window.localStorage.getItem("lang");
  const getLang = "ar";
  return (
    <div className="main">
      <Header />
      <section className="terms-container">
        <div className=" ">
          <div className="text-white">
            <h1
              className={`text-center text-xl font-bold  ${
                getLang === "ar" ? "arabic-lang" : ""
              }`}
            >
              {getLang === "en" && "TERMS AND CONDITIONS"}
              {getLang === "fr" && "TERMES ET CONDITIONS"}
              {getLang === "ar" && "الشروط والأحكام"}
            </h1>
            <div>
             
              <ul
                className={` pl-3 list-disc ${
                  getLang === "ar" ? "arabic-lang mr-4 pr-4 " : ""
                }`}
              >
                <li>{getLang === "ar" && "موافقة على الشروط: عند الوصول إلى موقع BlackNGreen أو تطبيقه، يوافق المستخدمون على الالتزام بالشروط والأحكام المحددة. تشكل هذه الشروط اتفاقًا قانونيًا ملزمًا بين المستخدم وشركة BlackNGreen."}</li>
                <li>
                  {getLang === "ar" &&"حقوق الملكية الفكرية: تؤكد BlackNGreen ملكيتها أو التحكم في جميع المحتويات، بما في ذلك النصوص والرسومات والصوت والعلامات التجارية، الموجودة على الموقع. يُمنع المستخدمون من استنساخ هذه المحتويات أو استغلالها دون الحصول على موافقة مكتوبة مسبقة."}
                </li>{" "}
                <li>
                  {getLang === "ar" &&
                    "تمثيل المستخدم: يجب على المستخدمين أن يؤكدوا أن لديهم القدرة القانونية على الموافقة على هذه الشروط وألا يصلوا إلى الموقع من خلال وسائل غير مصرح بها. يحتاج القصر إلى موافقة ولي الأمر لاستخدام الموقع."}
                </li>
                <li>{getLang === "ar" &&`الأنشطة الممنوعة: يُمنع على المستخدمين القيام بأنشطة تعرقل عمليات الموقع، أو تنتهك إجراءات الأمان، أو تنتهك حقوق شركة BlackNGreen. تشمل هذه الأنشطة الاحتيال والتحرش واستخدام غير المصرح به لموارد الموقع.`}</li>
                <li>{getLang === "ar" &&`مساهمات المستخدمين: بينما قد يقدم المستخدمون محتوى إلى الموقع، يجب عليهم التأكد من أنه لا ينتهك حقوق أي طرف ثالث أو يحتوي على مواد مرفوضة. من خلال تقديم المساهمات، يمنح المستخدمون شركة BlackNGreen الحق في استخدامها وفقًا لسياسات الموقع.`}</li>
                <li>{getLang === "ar" &&`ترخيص المساهمة: قد تقوم شركة BlackNGreen بالوصول إلى واستخدام المعلومات التي قدمها المستخدمون وفقًا لسياسة الخصوصية الخاصة بها. ومع ذلك، يحتفظ المستخدمون بملكية مساهماتهم.`}</li>
                <li>{getLang === "ar" &&`الإرسالات: تصبح أي معلومات أو اقتراحات أو ملاحظات تقدمها المستخدمين ملكًا لشركة BlackNGreen. يتنازل المستخدمون عن أي مطالبات لهذه الإرسالات ويمنحون شركة BlackNGreen حقوق غير مقيدة لاستخدامها لأغراض قانونية.`}</li>
                <li>{getLang === "ar" &&`إدارة الموقع: تحتفظ شركة BlackNGreen بالحق في مراقبة نشاط الموقع، واتخاذ إجراءات قانونية ضد المخالفين، وإدارة المحتوى كما يراها مناسبًا. ويشمل ذلك القدرة على إزالة أو تقييد محتوى المستخدمين.`}</li>
                <li>{getLang === "ar" &&`المدة والإنهاء: يوافق المستخدمون على هذه الشروط طوال فترة استخدامهم للموقع. تحتفظ شركة BlackNGreen بالحق في إنهاء الوصول للمستخدمين الذين ينتهكون الشروط أو يشاركون في أنشطة غير قانونية.`}</li>
                <li>{getLang === "ar" &&`التعديلات والانقطاعات: قد تقوم شركة BlackNGreen بتعديل أو تحديث أو إيقاف الموقع دون إشعار مسبق. يدرك المستخدمون أن الانقطاعات أو الأخطاء قد تحدث، ولكن لا تتحمل شركة BlackNGreen أي مسؤولية.`}</li>
                <li>{getLang === "ar" &&`التصحيحات: تحتفظ شركة BlackNGreen بالحق في تصحيح أي عدم دقة أو أخطاء موجودة على الموقع، بما في ذلك الأخطاء الطباعية أو المعلومات القديمة.`}</li>
                <li>{getLang === "ar" &&`إخلاء المسؤولية: يُقدم الموقع على أساس "كما هو"، دون أي ضمانات أو توعدات. تخلي BlackNGreen عن المسؤولية عن الأضرار الناجمة عن استخدام الموقع، بما في ذلك ولكن لا يقتصر على الإصابة الشخصية أو الضرر بالممتلكات.`}</li>
                <li>{getLang === "ar" &&`قيود المسؤولية: تحد BlackNGreen مسؤوليتها عن أي أضرار مباشرة أو غير مباشرة ناشئة عن استخدام الموقع، بما في ذلك فقدان الأرباح أو البيانات.`}</li>
                <li>{getLang === "ar" &&`التعويض: يوافق المستخدمون على تعويض BlackNGreen ضد أي مطالبات أو مسؤوليات ناشئة عن استخدامهم للموقع أو انتهاكهم لهذه الشروط.`}</li>
                <li>{getLang === "ar" &&`بيانات المستخدم: يتحمل المستخدمون مسؤولية أي بيانات تم نقلها إلى الموقع. بينما يقوم BlackNGreen بعمل نسخ احتياطية دورية، يقر المستخدمون بالمخاطر ويقبلونها المتعلقة بفقدان البيانات أو تلفها.`}</li>
                <li>{getLang === "ar" &&`الاتصالات الإلكترونية والمعاملات والتواقيع الإلكترونية: يوافق المستخدمون على تلقي الاتصالات الإلكترونية من BlackNGreen وعلى إجراء المعاملات بشكل إلكتروني. يتنازلون عن أي متطلبات للسجلات أو التواقيع غير الإلكترونية.`}</li>
                <li>{getLang === "ar" &&`متفرقات: تشكل هذه الشروط الاتفاقية الكاملة بين المستخدمين وBlackNGreen. يتنازل المستخدمون عن أي دفاعات تستند إلى الشكل الإلكتروني للاتفاقية. لا يتنازل فشل BlackNGreen في فرض أي بند عن حقوقها. الاتصال: للاستفسارات أو الشكاوي بخصوص الموقع أو هذه الشروط، يمكن للمستخدمين التواصل مع BlackNGreen على العنوان التالي: support@blackngreen.com.`}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermNCondition;
