import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { API_SWIP_URLS } from "../Shared/Constant";
import { Modal, Loader, Button, DecodeBase64, Header } from "../Components";
import useApiCall from "../Utilies/useApiCall";


function BuyMore() {
  const [congs, setCongs] = useState(false);
  const location = useLocation();
  const navigate=useNavigate()
  const uid = window.localStorage.getItem('user-msisdn')
  const [buy, buyApiCall, isLoading] = useApiCall(API_SWIP_URLS.Buy, { operatorId: "orange_egypt", packId: "dailytopup", userId: uid && uid })
  useEffect(() => {
    if (buy) {
      setCongs(true);
    }
    if (!uid) {
      navigate("/login")
   //   window.location.replace(location?.state?.config?.wap);
    }
  }, [buy, uid])
  const onHandleBuy = async() => {
    
    const result = await buyApiCall();
    navigate("/swipe-cards"); 
  };

  return (
    <div className="main">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className="bg-white rounded-lg  mt-4 ">
            <div className="bg-white text-center text-purple-600 p-1 border-l-8 border-l-[#ffde18] rounded-lg ">
              <h4 className={`mt-4 text-center text-black text-2xl font-medium  `}>
                {location?.state?.questions && "." + DecodeBase64(location?.state?.questions?.packs[0]?.packName)}
              </h4>
              {/* <p className="mb-2 text-gray-600 text-lg">
                {location?.state?.questions && DecodeBase64(location?.state?.questions?.packs[0]?.description)}
              </p> */}

              <div className="flex flex-col justify-center items-center mt-2">
                <Button
                  name={location?.state.questions && DecodeBase64(
                    location?.state?.questions?.packs[0]?.buttonText
                  )}
                  className="bg-[#ffde18] text-yellow-900 font-bold rounded-3xl p-2 capitalize button-theme m-2"
                  handleClick={onHandleBuy}
                />
                <Link to="/home" className="text-black text-sm mt-2">
                  {location?.state.questions && DecodeBase64(location?.state?.questions?.packs[0]?.notNow)}
                </Link>
              </div>
            </div>
          </div>
          {/* {congs && (
            <Modal
              type="congress"
              title={buy?.info?.title}
              subTitle={buy?.info?.subtitle}
              sub={buy?.text}
              button={buy?.info?.buttonText}
              congrats={buy}
            />
          )} */}
        </>
      )}
    </div>
  );
}

export default BuyMore;
