import React from "react";
import { Header } from "..";
import "./faq.scss";

const Faq = () => {
  // const getLang = window.localStorage.getItem("lang");
  const getLang = "ar";
  return (
    <div className="main">
      <Header />
      <section className=" faqs-container">
        <div class="">
          <div className="text-white">
            <h1
              className={`text-center text-xl font-bold  ${
                getLang == "ar" ? "arabic-lang" : ""
              }`}
            >
              {getLang === "en" && "Frequently Asked Questions"}
              {getLang === "fr" && "Frequently Asked Questions"}
              {getLang === "ar" && "أسئلة شائعة:"}
            </h1>
            <div className="py-1 px-4 list-data">
              <h6
                className={`font-bold  ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "How I subscribe?"}
                {getLang === "fr" && "Comment m'abonner ?"}
                {getLang === "ar" && "كيف يمكنني الاشتراك؟"}
              </h6>
              <p className={`mt-1  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "ar" && "يمكنك الاشتراك في خدمة PlayNwin عن طريق:"}
              </p>
              <ul
                className={` list-inside	 list-disc pl-3  ${
                  getLang == "ar" ? "arabic-lang" : ""
                }`}
              >
                <li className=" mt-1">
                  {getLang === "ar" &&
                    `زيارة موقع الويب "https://orangeeg.playfor-win.com"`}
                </li>
                <li className=" mt-1">
                  {getLang === "ar" &&
                    "طلب الاشتراك عبر الاتصال بالرقم 1169 من هاتفك المحمول"}
                </li>
                <li className=" mt-1">
                  {getLang === "ar" &&
                    `إرسال كلمة SUB إلى الرقم 1169 من هاتفك المحمول`}
                </li>
              </ul>

              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What is the duration of the service?"}
                {getLang === "fr" && "Quelle est la durée de la prestation ?"}
                {getLang === "ar" && "ما هي حزم الاشتراك المختلفة؟"}
              </h6>
              <p className={`mb-1 ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "ar" &&
                  "هناك حزمة اشتراك ليوم واحد: 10 جنيهات مصرية / يوم"}
              </p>

              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What are the prizes?"}
                {getLang === "fr" && "Quels sont les prix ?"}
                {getLang === "ar" && "هل هناك أنواع مختلفة من الاختبارات؟"}
              </h6>
              <p className={`mb-0  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" && "Swipe & Win service gives away:"}
                {getLang === "fr" && "Le service Swipe & Win offre :"}
                {getLang === "ar" && "نعم، هناك نوع واحد من الاختبارات: اختبارات السحب."}
              </p>
              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What are the prizes?"}
                {getLang === "fr" && "Quels sont les prix ?"}
                {getLang === "ar" && "ما هو اختبار السحب؟"}
              </h6>
              <p className={`mb-0  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" && "Swipe & Win service gives away:"}
                {getLang === "fr" && "Le service Swipe & Win offre :"}
                {getLang === "ar" && `في كل سؤال، ستحصل على بيان وخيارين للاختيار من بينهما: صحيح أو خطأ. للرد، ما عليك سوى استخدام إيماءة السحب في جهازك في اتجاه إجابتك - إما يسارًا للخطأ أو يمينًا للصواب خلال فترة زمنية محددة. بعد الانتهاء من لعبك، ستتلقى درجة تحدد ترتيبك في جدول المتصدرين.`}
              </p>
              <h6
                className={`font-bold  mt-1 ${
                  getLang === "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "What are the prizes?"}
                {getLang === "fr" && "Quels sont les prix ?"}
                {getLang === "ar" && "ما هي القواعد للعب في PlayNwin؟"}
              </h6>
              <p className={`mb-0  ${getLang === "ar" ? "arabic-lang" : ""}`}>
                {getLang === "en" && "Swipe & Win service gives away:"}
                {getLang === "fr" && "Le service Swipe & Win offre :"}
                {getLang === "ar" && `عند الاشتراك، ستكون لديك فرصة واحدة للعب اختبارات PlayNwin، اتبع هذه القواعد:`}
              </p>
              <ul
                className={` pl-3 list-inside list-disc  ${
                  getLang == "ar" ? "arabic-lang" : ""
                }`}
              >
                <li className=" mt-1">
                  {getLang === "en" &&
                    "XXMB once upon scoring 10 or more correct questions"}
                  {getLang === "fr" &&
                    "XXMB une fois après avoir obtenu 10 bonnes questions ou plus"}
                  {getLang === "ar" &&
                    "تستمر كل لعبة لمدة 10 ثوانٍ."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every day"}
                  {getLang === "fr" && "XXX USD en espèces chaque jour"}
                  {getLang === "ar" &&
                    "يتضمن كل سؤال بيانًا وصورة."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every week"}
                  {getLang === "fr" && "XXX USD en espèces chaque semaine"}
                  {getLang === "ar" &&
                    "في اختبار السحب، ستختار بين الصحيح أو الخطأ عن طريق السحب في الاتجاه المقابل خلال فترة زمنية محددة."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" && "XXX USD in Cash every month"}
                  {getLang === "fr" && "XXX USD en espèces chaque mois"}
                  {getLang === "ar" &&
                    "بعد الانتهاء من لعبك، ستتلقى درجة تحدد ترتيبك في جدول المتصدرين."}
                </li>
               
              </ul>
               
              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" && " If I win, how will I be informed?"}
                {getLang === "fr" && "Si je gagne, comment serais-je informé ?"}
                {getLang === "ar" && "هل تنتهي رصيدي في Play & Win؟"}
              </h6>
              <p className={`${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  "The winner will be contacted by Operator in the next days as to explain the process needed. So do not worry, just keep your phone on."}
                {getLang === "fr" &&
                  "Le gagnant sera contacté par l'opérateur dans les prochains jours afin d'expliquer le processus nécessaire. Alors ne vous inquiétez pas, gardez simplement votre téléphone allumé."}
                {getLang === "ar" &&
                  "ينتهي الرصيد الممنوح والمشترى في Play & Win في نهاية اليوم الميلادي إذا لم يتم استهلاكه حتى ذلك الوقت."}
              </p>

              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" && "How many times may I win each prize?"}
                {getLang === "fr" &&
                  "Combien de fois puis-je gagner chaque prix ?"}
                {getLang === "ar" && "هل هناك مجموعة من مستويات الصعوبة المتاحة للأسئلة؟ هل هناك أي أسئلة مصممة خصيصًا للأطفال والمراهقين؟"}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  "Each user is eligible to win the daily prize unlimited times and rest prizes only once."}
                {getLang === "fr" &&
                  "Chaque utilisateur est éligible pour gagner le prix quotidien un nombre illimité de fois et les prix de repos une seule fois."}
                {getLang === "ar" &&
                  `نعم، الاختبارات متاحة بمجموعة واسعة من أكثر من 10،000 سؤال بمستويات صعوبة متنوعة. بالإضافة إلى ذلك، هناك اختبارات مصممة خصيصًا للأطفال والمراهقين، مما يضمن أنهم يمكن أن يستمتعوا باللعب وهم يتعلمون ويوسعون معرفتهم. الاختبارات مناسبة للعمر وممتعة، مما يوفر وسيلة ممتعة للمتعلمين الصغار لاختبار معرفتهم ومهاراتهم.`}
              </p>

              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" && "هل تأتي الاختبارات بعدة لغات؟"}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" &&
                  `تم تصميم الاختبارات لتلبية احتياجات جمهور متنوع ومتاحة باللغة العربية، مما يجعل الخدمة سهلة الوصول وسهلة الاستخدام.`}
              </p>
              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" && "هل هناك أي حد لعدد الأسئلة التي يمكن للمستخدم لعبها في اليوم؟"}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" &&
                  `يمكنك لعب 5 أسئلة في اليوم.`}
              </p>
              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" && "ما هي الجوائز؟ "}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" &&
                  `يعمل نظام المكافآت في PlayNwin على النحو التالي:`}
              </p>
              <ul className={` list-disc pl-3 ${getLang==="ar"?"arabic-lang list-inside ":""}`}>
                <li className=" mt-1">
                  {getLang === "en" && "Each game lasts for 55 seconds."}
                  {getLang === "fr" && "Chaque partie dure 55 secondes."}
                  {getLang === "ar" && `بمجرد المشاركة في الاختبارات المختلفة على مدى فترة زمنية محددة، يمكنك كمستخدم كسب النقاط. واستنادًا إلى النقاط التي تم تجميعها، ستصنف في جدول المتصدرين.بمجرد المشاركة في الاختبارات المختلفة على مدى فترة زمنية محددة، يمكنك كمستخدم كسب النقاط. واستنادًا إلى النقاط التي تم تجميعها، ستصنف في جدول المتصدرين.`}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "Each question is consisted of a statement and a photo."}
                  {getLang === "fr" && "Chaque question est composée d'une affirmation et d'une photo."}
                  {getLang === "ar" && "بعد الانتهاء من كل دورة مكافأة، سيتم الإعلان عن الفائزين."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "User has to Swipe right for Correct or left for Wrong."}
                  {getLang === "fr" && "L'utilisateur doit balayer vers la droite pour corriger ou vers la gauche pour se tromper."}
                  {getLang === "ar" && "يستند تحديد الفائزين وتوزيع الجوائز إلى الشروط والأحكام المحددة من قبل الخدمة."}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    "Be careful! Every wrong reply deducts 5 seconds from the remaining time!"}
                  {getLang === "fr" && "Fais attention! Chaque mauvaise réponse déduit 5 secondes du temps restant !"}
                  {getLang === "ar" && "بعد الانتهاء من كل دورة مكافأة، سيقوم فريق PlayNwin بإبلاغ الفائزين المختارين."}
                </li>
               
              </ul>

              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" && "Does my Swipe & Win credits expire?"}
                {getLang === "fr" && "Mes crédits Swipe & Win expirent-ils ?"}
                {getLang === "ar" && ""}
              </h6>
              <p className={`${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" && "سيتم الاتصال بالفائز من قبل المشغل في الأيام القليلة القادمة لشرح الإجراءات اللازمة. لذا لا تقلق، فقط حافظ على هاتفك مفتوحًا."}
              </p>

              <h6 className={`font-bold  mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "ar" &&
                 "هل يمكنني التحقق من ترتيبي ونقاطي؟"}
              </h6>
              <p className={`${getLang==="ar"?"arabic-lang":""}`}>
               {getLang === "ar" &&
                 `نعم، يمكنك التحقق من موقفك الحالي على جدول المتصدرين في التطبيق. يمكنك أيضًا اكتشاف تقدمك مع صفحة Streak (التقدم) الجديدة، التي يمكن الوصول إليها من صفحة الجدول الزمني. توفر هذه الميزة الجديدة نظرة عامة واضحة على أدائك الأخير، بما في ذلك عدد الأيام المتتالية التي لعبتها ونقطتك اليومية. تساعدك صفحة Streak على وضع أهداف شخصية ومراقبة تقدمك نحو تحقيقها.`}
              </p>

              <p className={`${getLang==="ar"?"arabic-lang":""}`}>
               {getLang === "ar" &&
                 `ابق على اطلاع دائم على صفحة Streak لتبقى متحفزًا وعلى المسار الصحيح في رحلتك في أخذ الاختبارات!`}
              </p>

              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
               {getLang === "ar" && "هل يمكن للمتسابقين تحويل جائزتهم إلى شخص آخر؟"}
              </h6>
              <p className={`${getLang==="ar"?"arabic-lang":""}`}>
               {getLang === "ar" &&
                  `زة/الهدية في الاختبارات لا يمكن نقلها أو تحويلها تحت أي ظرف من الظروف.`}
              </p>

              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  `Can multiple users avail of the benefits of the same subscription? `}
                {getLang === "ar" &&
                  `هل يمكن لعدة مستخدمين الاستفادة من فوائد نفس الاشتراك؟`}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  "The benefits of one subscription account can be used only by one registered user on a single mobile device at a time and may not be used in respect of multiple devices with a single registration. "}
               {getLang === "ar" &&
                  "يمكن استخدام فوائد حساب الاشتراك الواحد فقط من قبل مستخدم مسجل واحد على جهاز محمول واحد في وقت واحد وقد لا يتم استخدامها فيما يتعلق بأجهزة متعددة بتسجيل واحد."}
              </p> 
              <h6 className={`font-bold mt-1 ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  `How can I unsubscribe? How can I unsubscribe?  `}
                {getLang === "ar" &&
                  `كيف يمكنني إلغاء الاشتراك؟`}
              </h6>
              <p className={` ${getLang==="ar"?"arabic-lang":""}`}>
                {getLang === "en" &&
                  "To unsubscribe simply send 'UNSUB' to the shortcode 1169 or you can follow the following steps: "}
               {getLang === "ar" &&
                  `لإلغاء الاشتراك، قم ببساطة بإرسال "UNSUB" إلى الرمز القصير 1169 أو يمكنك اتباع الخطوات التالية:`}
              </p> 
              <ul className={` list-disc pl-3 ${getLang==="ar"?"arabic-lang list-inside ":""}`}>
                <li className=" mt-1">
                  {getLang === "en" && "Go to the menu category in the app "}
                  {getLang === "ar" && `اذهب إلى فئة القائمة في التطبيق`}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    `Select the "Profile" option `}
                  {getLang === "fr" && "Chaque question est composée d'une affirmation et d'une photo."}
                  {getLang === "ar" && ` حدد الخيار "الملف الشخصي"`}
                </li>
                <li className=" mt-1">
                  {getLang === "en" &&
                    `Click on "Unsubscribe"`}
                 {getLang === "ar" && ` انقر على "إلغاء الاشتراك"`}
                </li>
               
               
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Faq;
