import React, { useEffect, useState } from "react";
import { API_SWIP_URLS } from "../Shared/Constant";
import { POST } from "../Shared/Axios";
import { swipeLogo } from "../Utilies/ImageExports";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Modal,
  DecodeBase64,
  Button,
  Loader,
  Header,
  ErrorPage,
} from "../Components";
import useApiCall from "../Utilies/useApiCall";
import ReactGA from "react-ga4";
import GaHandler from "../Utilies/GoogleAnalytics";
import Slider from "../Components/Slider";
import Cards from "../Assets/Images/home1.png";
import homeBanner from "../Assets/Images/home2.png";
import Text from "../Assets/Images/ArbicText.png";
import RightImage from "../Assets/Images/RightWhiteArrow.png";
export const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const getLang = window.localStorage.getItem("lang");
  const [apiFailure, setAPIFailure] = useState(false);
  const [queryParams, setqueryParams] = useSearchParams(window.location.search);
  const UID = queryParams.get("uid");
  const fisttimeUid = UID ? DecodeBase64(UID).split("=")[1] : "";
  const uid = window.localStorage.getItem("user-msisdn");
  const getAppversion = localStorage.getItem("app-version");
  const [buy, buyApiCall, loading] = useApiCall(API_SWIP_URLS.Buy, {
    operatorId: "orange_egypt",
    packId: "dailytopup",
    userId: uid && uid,
  });

  console.log(uid, "uid", UID);
  const [questions, questionApiCall, questionLoading] = useApiCall(
    API_SWIP_URLS.Questions,
    {
      operatorId: "orange_egypt",
      questions: true,
      userId: uid || fisttimeUid,
    }
  );
  const [config, configApiCall, configLoading] = useApiCall(
    API_SWIP_URLS.Config,
    { operatorId: "orange_egypt" }
  );

  useEffect(() => {
    configApiCall();
    registerApiCall();
  }, []);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/home",
      title: "Home Page visit",
    });
    //   ReactGA.send(location.pathname + location.search );
  }, []);
  useEffect(() => {
    window.localStorage.setItem("lang", selectedLanguage);
    if (selectedLanguage) {
      configApiCall();
    }
  }, [selectedLanguage]);

  const registerApiCall = () => {
    if (UID) {
      window.localStorage.setItem("uid", UID);
      const Msisdn = DecodeBase64(UID);
      const newUserNumber = Msisdn.split("=")[1];
      var updatedMsisdn = newUserNumber.split("&")[0];
      console.log("removeend", updatedMsisdn);
      window.localStorage.setItem("user-msisdn", updatedMsisdn);
    }
    const regData = {
      userId: updatedMsisdn || uid,
      operatorId: "orange_egypt",
      questions: true,
    };
    (UID || uid) &&
      POST(API_SWIP_URLS.Register, regData)
        .then((res) => {
          setRegisterData(res);
          //  setIsLoading(false);
          GaHandler("api call", "checkSub", "event chal gya");
          if (
            res.currentStatus.toLowerCase() !== "active" &&
            !res.subscription.status
          ) {
            navigate("/login")
          }
          if (res.status === "FAILURE") {
            setAPIFailure(true);
          }
        })
        .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (questions?.status === "SUCCESS" && questions?.questions) {
      questions?.status === "SUCCESS" && navigate("/swipe-cards");
    }
    if (questions?.status === "FAILURE") {
      if (questions?.packs && questions?.packs[0]?.packType === "TOPUP") {
        buyApiCall();
      } else if (
        questions?.packs &&
        questions?.packs[0]?.packType === "SUBSCRIPTION"
      ) {
       // window.location.replace(config.wap);
       localStorage.removeItem("user-msisdn")
       navigate("/login")
      } else {
        navigate("/info");
      }
    }
    if (config) {
      localStorage.setItem("app-version", config.version_id);
      // if (getAppversion !== config?.version_id) {
      //   console.log("app version", getAppversion)
      //   localStorage.clear();
      // }
      window.localStorage.setItem("wap", config.wap);
    }
  }, [questions, config]);
  useEffect(() => {
    if (buy?.status?.toUpperCase() == "SUCCESS") {
      navigate("/swipe-cards");
    }
  }, [buy]);

  const onHandleBuyMore = () => {
    setIsLoading(true);
    if (UID) {
      // window.localStorage.setItem("uid", "bT05MTc0Mjg2ODkzMDU=");
      const Msisdn = DecodeBase64(UID);
      const newUserNumber = Msisdn.split("=")[1];
      var updatedMsisdn = newUserNumber.split("&")[0];
      console.log("removeend", updatedMsisdn);
      window.localStorage.setItem("user-msisdn", updatedMsisdn);
    } else if (!uid) {
      setIsLoading(false);
      navigate("/login")
     // window.location.replace(config.wap);
    }
    const regData = {
      userId: updatedMsisdn || uid,
      operatorId: "orange_egypt",
      questions: true,
    };
    (UID || uid) &&
      POST(API_SWIP_URLS.Register, regData)
        .then((res) => {
          setRegisterData(res);
          //  setIsLoading(false);
          GaHandler("api call", "checkSub", "event chal gya");
          questionApiCall();
          if (res.status === "FAILURE") {
            setAPIFailure(true);
          }
        })
        .catch((err) => console.log(err));
  };
  const handleRightArrowClick = () => {
    if (!uid) {
      setIsLoading(false);
      navigate("/login")
     // window.location.replace(config.wap);
    } else {
      navigate("/question-info");
    }
  };

  return (
    <div className="main home h-screen flex flex-col relative">
      {isLoading || questionLoading || configLoading ? <Loader /> : ""}
      <>
        <Header />
        <div className=" p-4  text-white " style={{ position: "relative" }}>
          <h1 className="font-extrabold text-xl text-center mt-8">
            {config ? DecodeBase64(config?.launchscreen?.title) : ""}
          </h1>
          {/* <img src={Text} className="mt-10 ml-8 z-10" alt="text" /> */}
          <img
            src={Cards}
            height={"120vh"}
            width={"240vw"}
            style={{ maxHeight: "200vh", maxWidth: "250vw" }}
            className="sm:mt-0 mt-10 flex ml-10 sm:ml-20 justify-center z-10"
            onClick={onHandleBuyMore}
          />

          {/* <hr
            style={{
              border: "none",
              borderTop: "1px dotted white",
              width: "100%",
              height: "100%",
              // position:"fixed"
            //  marginTop: "10%",
            }}
          /> */}
        </div>

        <div style={{ height: "100%", marginTop: "5%" }}>
          <img
            src={homeBanner}
            height={"60%"}
            width={"92%"}
            max-width={"480px"}
            style={{
              position: "fixed",
              bottom: 10,
              left: "50%",
              transform: "translateX(-50%)",
              maxWidth: "480px",
              maxHeight: "180px",
              marginTop: "10%",
              marginBottom: "2%",
            }}
            onClick={handleRightArrowClick}
          />
        </div>

        {apiFailure && <ErrorPage text={"register"} />}
      </>
    </div>
  );
};
