import "./Assets/Styles/Styles.scss";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Home, Swipe4win, Info, BuyMore, SelectPage, Results, ProtectedRoute } from "./Swip4Win";
import { Faq, HowToPlay, TermNCondition, LeaderBoard } from "./Components/SideBar";
import {ErrorPage} from "./Components";
import useOnline from "./Utilies/useOnline";
import ReactGA from "react-ga4";
import Slider from "./Components/Slider";
import ResultInfo from "./Swip4Win/ResultInfo";
import LoginScreen from "./Swip4Win/Subscriiption Screen/LoginScreen";
import OtpScreen from "./Swip4Win/Subscriiption Screen/OtpScreen";
import Subscribe from "./Swip4Win/Subscriiption Screen/Subscribe";
import Congratulations from "./Swip4Win/Subscriiption Screen/Congratulations";

function App() {
  const online = useOnline()
 
  return (
    <div className="App">
      {
        online ? (
          <Router>
            <Routes>
              <Route path="/" exact element={<LoginScreen />} />
              <Route path="/home" exact element={<Home />} />
              <Route path="/login" exact element={<LoginScreen />} />
              <Route path="/otp" exact element={<OtpScreen />} />
              <Route path="/subscribe" exact element={<Subscribe />} />
              <Route path="/congratulations" exact element={<Congratulations />} />
              <Route path="/info" element={<ProtectedRoute Component={Info} />} />
              <Route path="/buy" element={<ProtectedRoute Component={BuyMore} />} />
              <Route path="/swipe-cards" element={<ProtectedRoute Component={Swipe4win} />} />
              <Route path="/results" element={<ProtectedRoute Component={Results} />} />
              <Route path="/question-info" element={<ProtectedRoute Component={Slider} />} />
              <Route path="/info-result" element={<ProtectedRoute Component={ResultInfo} />} /> 
              <Route path="/language" element={<SelectPage />} />
              <Route path="/leaderboard" element={<LeaderBoard />} />
              <Route path="/faqs" element={<Faq />} />
              <Route path="/how-to-play" element={<HowToPlay />} />
              <Route path="/errorpage" element={<ErrorPage />} />
              <Route path="/terms-conditions" element={<TermNCondition />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        ) : (
          <div className="text-xl font-bold text-white flex  h-screen items-center justify-center text-center">
            your internet is not working
          </div>
        )
      }
    </div >
  );
}

export default App;
