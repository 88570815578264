import { useState } from "react";
import { POST } from "../Shared/Axios";

const useApiCall = (url, data = {}) => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false)

  const apiCall = async () => {
    setLoading(true);
    try {
      const responseData = await POST(url, data);
      setApiData(responseData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return [apiData, apiCall, loading];
};
export default useApiCall;