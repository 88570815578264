import React, { useEffect, useMemo, useRef, useState } from "react";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import { DecodeBase64, Loader, Modal } from "../Components";
import { useNavigate } from "react-router-dom";
import {
  BtnBg,
  WrongIcon,
  CorrectIcon,
  swipeLogo,
} from "../Utilies/ImageExports";
import CardTemp from "./Card";
import useApiCall from "../Utilies/useApiCall";

export const Swipe4win = () => {
  const [count, setCount] = useState(10);
  const [ansApiCount, setAnsApiCount] = useState(0);

  const [quizEnd, setQuizEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const [buypopup, setBuypopup] = useState(false);
  const [endQuestion, setEndQuestion] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [propsData, setPropsData] = useState({
    quizEnd: "",
    questions: "",
    count: "",
  });
  const [loadingTime, setLoadingTime] = useState(true);
  const uid = window.localStorage.getItem('user-msisdn')
  const [questions, questionApiCall, questionLoading] = useApiCall(
    API_SWIP_URLS.Questions,
    { operatorId: "orange_egypt", questions: true, userId: uid }
  );
  const [startData, startApiCall, startLoading] = useApiCall(
    API_SWIP_URLS.Start,
    {
      operatorId: "orange_egypt",
      questions: true,
      userId: uid,
    }
  );
  // const [answer,awsAPICall,answerLoading]=useApiCall()
  // const [quizEnd, endApiCall, endLoading] = useApiCall(API_SWIP_URLS.End,  {
  //   userId: "917428689305",
  //   sessionId:startData&& startData?.sessionId,
  //   operatorId: "orange_egypt",
  // });
 
  const getlang = window.localStorage.getItem("lang");
  useEffect(() => setCurrentIndex(questions?.questions?.length - 1), [questions?.questions]);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions?.questions && currentIndex < 0&&questions?.questions?.length===ansApiCount) {
      setLoading(true);
      const endData = {
        userId: uid,
        sessionId: startData.sessionId,
        operatorId: "orange_egypt",
      };
      POST(API_SWIP_URLS.End, endData)
        .then((res) => {
          setLoading(false);
          setCount(0);
          setEndQuestion(true);
          setPropsData({
            ...propsData,
            quizEnd: res,
            questions: questions?.questions,
            count: count,
          });
          // navigate("/results",{state:{quizEnd:res},questions:questions})
          setQuizEnd(res);
          if (res.status !== "SUCCESS") {
            navigate("/info");
          }
        })
        .catch((err) => console.log(err));
      // endApiCall()
    }
    console.log(endQuestion, "endQuestion");
  }, [currentIndex,ansApiCount]);

  useEffect(() => {
    if (quizEnd) {
      navigate("/results", { state: { propsData: propsData } });
    }
    if (quizEnd && quizEnd?.status !== "SUCCESS") {
      navigate("/info");
    }
    if (questions) {
      if (questions?.status !== "SUCCESS" && questions?.packs?.length == 0) {
        navigate("/info");
      } else if (
        questions.status !== "SUCCESS" &&
        questions?.packs?.length > 0
      ) {
        // setBuypopup(true);
         navigate("/buy", { state: { questions: questions } });
      } else if (
        Object.keys(questions).length === 3 &&
        questions?.status.toUpperCase() !== "SUCCESS" &&
        questions?.reason !== "" &&
        questions?.questions.length === 0
      ) {
        navigate("/home");
      }
    }
    if (questions?.questions && questions?.questions?.length > 0&&!quizEnd) {
      startApiCall();
    }
    if (quizEnd) {
      setEndQuestion(true);
      setPropsData({
        ...propsData,
        quizEnd: quizEnd,
        questions: questions?.questions,
        count: count,
      });
    }
  }, [quizEnd, questions]);

  useEffect(() => {
    questionApiCall();
  }, []);

  useEffect(() => {
    if(!loadingTime){
    if (count > 0) {
      var interval = setInterval(() => {
        setCount((count) => count - 1);
      }, 1000);

    }
  }
    if (currentIndex !== -1 && count == 0) {
      swipe("up");
    }
    return () => clearInterval(interval);
  }, [count,loadingTime]);

  function reset() {
    setCount(10);
  }

  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(questions?.questions.length)
        .fill(0)
        .map((i) => React.createRef()),
    [questions?.questions]
  );
  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swipe = (dir) => {
    if (currentIndex < questions?.questions?.length) {
      childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
   // swiped(dir, currentIndex);
    if (dir) {

      console.log("inside dir swipe", dir);
      reset();
    }
  };
  const btnStyle = {
    backgroundImage: `url(${BtnBg})`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    width: "fit-content",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    zIndex: 2,
  };


  const handleImageLoad = () => {
   
    setLoadingTime(false);
  };
console.log(loadingTime,"loadingTime");
  const startTime = performance.now();
  const getLang="ar"
  return (
    <div className="main swipe_bg w-full h-screen overflow-hidden ">
      {(questionLoading || startLoading || loading||loadingTime) ? <Loader /> : ""}
      {/* <Header /> */}
      <div className="swipe-logo p-2">
        <img
          src={swipeLogo}
          alt="logo"
          width="150"
          onClick={() => navigate("/")}
        />
      </div>
     
     
      <div className="flex flex-col p-1 overflow-hidden ">
      <div className="flex justify-center">
      <div className="badge  rounded-3xl w-fit mb-1  p-1 text-black font-bold bg-[#ffde18] text-center ">
            {count > 9 ? `00:${count}s` : `00:0${count}s`}
          </div>
      </div>
        <div className="swipe">
          {(questions?.questions?.length && startData) && Object.keys(startData)?.length ? (
            <CardTemp
              questions={questions?.questions}
              startData={startData}
              childRefs={childRefs}
              setCurrentIndex={setCurrentIndex}
              currentIdex={currentIndex}
              handleImageLoad={handleImageLoad}
              setCount={setCount}
              setAnsApiCount={setAnsApiCount}
            />
          ) : null}
        </div>
       
        <div className="absolute bottom-1 left-1/2 -translate-x-1/2  flex flex-col justify-center items-center max-w-[500px] w-full">
         
          <div className={`text-white font-bold pb-1 px-6 text-center  ${getLang==="ar"?"arabic-lang":""}`} >
            {(questions?.questions?.length>0&&currentIndex>=0)&&DecodeBase64(questions?.questions?.[currentIndex]?.questionText)}
          </div>
          <div className="m-auto btns-bg" >
            <div className="flex flex-row mt-1 m-auto ">
              <img
                src={WrongIcon}
                onClick={() => swipe("left")}
                alt=""
                width="80"
                className="cursor-pointer"
              />
              <img
                src={CorrectIcon}
                className="ml-1 cursor-pointer"
                onClick={() => swipe("right")}
                alt=""
                width="80"
              />
            </div>
          </div>
        </div>
        {buypopup && <Modal type="buypopup" />}
        {/* <div className="swipe-cards-background"></div> */}
      </div>
    </div>
  );
};
