import React, { useState } from "react";
import { Header, Loader } from "../../Components"; // Assuming this is your custom Header component
import { useNavigate } from "react-router-dom";
import Right from "../../Assets/Images/select_icon.png";
import { POST } from "../../Shared/Axios";
import { API_SWIP_URLS } from "../../Shared/Constant";
function Subscribe() {
  // This function would handle the subscription logic
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getLang = "ar"
  const CheckSubApi = () => {
    setLoading(true);
    const userID = localStorage.getItem("user-msisdn");
    const data = {
      operatorId: "orange_egypt",
      userId: userID,
      // userId:"917428689305",
    };
    POST(API_SWIP_URLS.CheckSub, data)
      .then((res) => {
        console.log(res,"checksub");
        
        

        if(res.currentStatus==="pending"){
          setLoading(true); 
          setTimeout(() => {
            setLoading(false); 
            CheckSubApi(); 
          }, 5000);
        }
        else if(res.currentStatus==="active" ){
          navigate("/congratulations");
          localStorage.setItem("checkSub", true);
        }
        // else if(res.currentStatus==="new"){
        //   navigate("/subscribe");
        // }
        else{
          navigate("/congratulations");
          localStorage.setItem("checkSub", false);
        }
        setLoading(false);
      })
      
      .catch((err) => console.log(err));
  }
  const handleSubscribe = () => {
    setLoading(true); 
    console.log("Subscribed!");
    const userID = localStorage.getItem("user-msisdn");
    const data = {
      operatorId: "orange_egypt",
      userId: userID,
      //userId:"917428689305",
    };
    POST(API_SWIP_URLS.Subscribe, data)
      .then((res) => {
        console.log(res);
        if (res.status === "unsuccessful") {
          // localStorage.removeItem("user-msisdn");
          // navigate("/congratulations");
          // localStorage.setItem("checkSub", false);
          CheckSubApi()
        } else {
          // localStorage.setItem("checkSub", true);
          // navigate("/congratulations");
          CheckSubApi()
        }
        setLoading(false); 
      })
      .catch((err) => {
        console.log(err);
      });

    // Add your subscription logic here
  };

  return (
    <div className="main h-screen">
      <Header text={"login"} />
      {loading ? <Loader /> : ""}
      <div className="subscription-container text-center pt-24">
        <div className="subscription-checkmark bg-white  p-2 rounded-full inline-block">
          {/* Here you would insert your checkmark icon */}
          <img src={Right} alt="Subscription" />
        </div>
        <div className="subscription-info bg-[#9876ff] h-[60%] text-white mx-auto mt-8 p-6 rounded-2xl -mt-6 border-white border-2">
          <h2 className={`text-2xl font-bold pt-6 ${getLang==="ar"?"arabic-lang":""}`}>اشتراك يومي</h2>
          <p className={`text-3xl font-bold mt-4 pt-10 ${getLang==="ar"?"arabic-lang":""}`}>1 جنيه مصري</p>
        </div>
        <p className={`mt-4 text-white ${getLang==="ar"?"arabic-lang":""}`}>للوصول إلى محتوى غير محدود</p>
          <p className={`mt-2 text-yellow-700 ${getLang==="ar"?"arabic-lang":""}`}>
          اضغط لتزيد فرصة الفوز ب ٥٠ الف جنيه من خلال الإجابة على خمسة أسئلة يوميًا!
          </p>
        <button
          onClick={handleSubscribe}
          className={`confirm-button ${getLang==="ar"?"arabic-lang":""}`}
        >
          اشترك الآن
        </button>
      </div>
    </div>
  );
}

export default Subscribe;
