import React, { useEffect, useState } from "react";
import { RedBanner,BgImage } from "../Utilies/ImageExports";
import { API_SWIP_URLS } from "../Shared/Constant";
import {Button ,DecodeBase64,Header, Loader}from "../Components";
import { useNavigate } from "react-router";
import useApiCall from "../Utilies/useApiCall";
import { LikeIcon } from "../Utilies/ImageExports";

function Info() {
  const [playlater, setPlaylater] = useState(false);
  const navigate=useNavigate();
  const uid=window.localStorage.getItem('user-msisdn')
  const [ questions, questionApiCall, isLoading ]= useApiCall(
    API_SWIP_URLS.Questions,
    { operatorId: "orange_egypt", questions: true, userId:uid }
  );
  useEffect(() => {
    questionApiCall()
  }, []);

  useEffect(() => {
    if (questions) {
      if(questions.status == "SUCCESS"){
        navigate("/swipe-cards")
      }else if(questions.status === "FAILURE"&& questions?.packs && questions?.packs[0]?.packType === "TOPUP"){
        navigate("/buy", { state: { questions: questions } });}
      }
    }, [questions]);
  const style = {
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: " center center",
    width: " 100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "auto",
    textAlign: "center",
    padding: "10px",
    marginTop: "1rem",
    borderRadius: "10px",
    objectFit: "cover",
  };
  
  const infoStyle = {
    backgroundImage: `url(${RedBanner})`,
    height: "50px",
    textAlign: " center",
    width: "100%",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    fontSize: ".9rem",
    color: " #fff",
  };
  const HandlePlayMore = () => {
    navigate("/home")
   // setPlaylater(true);
   // questionApiCall()
   // Questions()
  };
  const getLang="ar"
  return (
    <div className="main">
      {isLoading?<Loader />:null}
      <Header />
      <div style={style}>
        <div className={`info-title  ${getLang==="ar"?"arabic-lang":""}`}>
          {questions?.info && DecodeBase64(questions?.info?.title)}
        </div>
        <div className={`info-subtitle  ${getLang==="ar"?"arabic-lang":""}`}>
          {questions?.info && DecodeBase64(questions?.info?.subtitle)}
        </div>
        {/* <div className="info-info" style={infoStyle}>
          {questions?.info && DecodeBase64(questions?.info?.info)}
        </div> */}
        <div className={`info-text  ${getLang==="ar"?"arabic-lang":""}`}>
          {questions?.info && DecodeBase64(questions?.info?.text)}
        </div>
        <hr className="info-hr" />
        <Button
          className="bg-[#ffde18] text-yellow-900 font-bold rounded-3xl p-3 mt-3 capitalize button-theme"
          name={questions?.playLater&&DecodeBase64(questions?.playLater?.buttontext)}
          handleClick={HandlePlayMore}
        />
        {/* {playlater && (
          <div className={`playlater-text  ${getLang==="ar"?"arabic-lang":""}`}>
            {questions?.playLater && DecodeBase64(questions?.playLater?.next)}
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Info;
