import React, { useEffect, useState } from "react";
import { Header, Loader } from "../../Components";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import OTP from "../../Assets/Images/otp.png";
import { POST } from "../../Shared/Axios";
import { API_SWIP_URLS } from "../../Shared/Constant";
function OtpScreen() {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(300);
  const [showOtp, setShowOtp] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isOtpVerified, setIsOtpVerified] = useState(false); // New state to track OTP verification
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getLang = "ar"
  const handleOtpChange = (event) => {
    const value = event.target.value;
    setOtp(value.slice(0, 4)); // Ensures only 4 digits are taken
  };
  useEffect(() => {
    // Set up the interval to decrement the timer every second
    const id = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setIntervalId(id);

    // Clean up the interval on component unmount
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // Stop the timer when it reaches 0
    if (timer <= 0) {
      clearInterval(intervalId);
    }
  }, [timer, intervalId]);

  const toggleOtpVisibility = () => {
    setShowOtp(!showOtp);
  };

  const formattedTimer = `${Math.floor(timer / 60)
    .toString()
    .padStart(2, "0")}:${(timer % 60).toString().padStart(2, "0")}`;
  const isButtonDisabled = otp.length !== 4;
  const CheckSubApi = () => {
    const userID = localStorage.getItem("user-msisdn");
    const data = {
      operatorId: "orange_egypt",
      userId: userID,
      // userId:"917428689305",
    };
    POST(API_SWIP_URLS.CheckSub, data)
      .then((res) => {
        console.log(res,"checksub");
        setLoading(false);
        

        if(res.currentStatus==="pending"){
          setLoading(true); 
          setTimeout(() => {
            setLoading(false); 
            CheckSubApi(); 
          }, 5000);
        }
        else if(res.currentStatus==="active" ){
          navigate("/home")
        }
        else if(res.currentStatus==="new"){
          navigate("/subscribe");
        }
        else{
          navigate("/congratulations");
          localStorage.setItem("checkSub", false);
        }
      })
      .catch((err) => console.log(err));
  }
  const otpMatch = () => {
    setLoading(true);
    const userID = localStorage.getItem("user-msisdn");
    const data = {
      operatorId: "orange_egypt",
      userId: userID,
      otp: otp,
      //  userId:"917428689305",
    };
    POST(API_SWIP_URLS.OtpVerify, data)
      .then((res) => {
        console.log(res);
      //  setLoading(false);
        if (res.status !== "failure") {
        //  setIsOtpVerified(true);
          CheckSubApi();
          
         // navigate("/subscribe");
        }else{
          setIsOtpVerified(true);
          setLoading(false);
        }
        // navigate("/subscribe");
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  const sendOtp = () => {
    setLoading(true)
    const userID = localStorage.getItem("user-msisdn");
    const data = {
      operatorId: "orange_egypt",
      userId: userID,
      // userId:"917428689305",
    };
    POST(API_SWIP_URLS.SendOtp, data)
      .then((res) => {
        console.log(res);
          setLoading(false);
        //  window.localStorage.setItem("number",phoneNumber)
        if (res.status !== "failure") {
       //   CheckSubApi();
          //  navigate("/otp");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="main h-screen">
      <Header text={"login"} />
      {loading ? <Loader /> : ""}
      <div className="otp-container bg-[#440084]">
        <div className="flex justify-center">
          <img src={OTP} alt="otp" width={"90px"} height={"90px"} />
        </div>
        <h1 className={`text-white font-bold text-3xl mb-4 mt-4 pt-4 ${getLang==="ar"?"arabic-lang":""} `}>
          {!isOtpVerified ? "الرقم السري المتغير OTP" : "عذرا"}
        </h1>
        <p className={`text-white text-lg pt-4 ${getLang==="ar"?"arabic-lang":""}`}>
          {!isOtpVerified
            ? "الرجاء إدخال الرمز المكوّن من 4 أرقام الذي تلقيته على هاتفك المحمول"
            : "الرقم السري المتغير OTP الذي أدخلته غير صحيح"}
        </p>
        <p className={`text-yellow-700 text-lg ${getLang==="ar"?"arabic-lang ":""}`}>
          {!isOtpVerified ? "" : "حاول مرة أخرى"}
        </p>
        <div className="otp-input-container">
          <input
            type={showOtp ? "password" : "number"}
            className="otp-input "
            onChange={handleOtpChange}
            value={otp}
            maxLength="4"
          />
          {otp.length > 0 && (
            <button onClick={toggleOtpVisibility} className="eye-button z-10">
              {showOtp ? <FaEyeSlash /> : <FaEye />}
            </button>
          )}
        </div>
        <div className={`timer text-white ${getLang==="ar"?"arabic-lang":""}`}>{`احصل على الرقم السري المتغير المكوّن من 4 أرقام على هاتفك (${formattedTimer})`}</div>

        <div className={`resend text-white pt-2 ${getLang==="ar"?"arabic-lang":""}`}>
        لم تتلقي رسالة نصية؟{" "}
          <a href="#" onClick={sendOtp}>
          إعادة إرسال الرمز
          </a>
        </div>
        <button
          className={`confirm-button pt-2 ${getLang==="ar"?"arabic-lang":""}`}
          disabled={isButtonDisabled}
          onClick={otpMatch}
        >
          تأكيد الشراء
        </button>
      </div>
    </div>
  );
}

export default OtpScreen;
