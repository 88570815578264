import React from "react";
import { useNavigate } from "react-router";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import GaHandler from "../Utilies/GoogleAnalytics";
import Button from "./Buttons";

const ErrorPage = ({ text = "" }) => {
  const navigate = useNavigate()
  const onHandleTryAgain = () => {
    const uid = localStorage.getItem('user-msisdn')
    if (text == "register") {
      const regData = {
        userId: uid,
        operatorId: "orange_egypt",
        questions: true,
      };
      (uid) &&
        POST(API_SWIP_URLS.Register, regData)
          .then((res) => {
            console.log(res);
            //  setIsLoading(false);
            GaHandler("api call", "checkSub", "event chal gya");
            navigate("/home")
          })
          .catch((err) => console.log(err));
    }
  }
  return (
    <div className="h-screen">
      <div className="flex justify-center items-center h-[50%]">
        <h3>Something went Wrong</h3>
      </div>
      <div className="flex justify-center h-[20%]">
        <Button className="bg-[#ffde18] m-auto" name="Try Again" handleClick={onHandleTryAgain} />
      </div>
    </div>
  );
};

export default ErrorPage;
