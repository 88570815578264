import React, { useState } from "react";
import { Login } from "../../Utilies/ImageExports";
import { Header, Loader } from "../../Components";
import { useNavigate } from "react-router-dom";
import { POST } from "../../Shared/Axios";
import { API_BASE_URL, API_SWIP_URLS } from "../../Shared/Constant";

function LoginScreen() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getLang="ar"
  // Function to handle the change event on the input
  const handleInputChange = (event) => {
    // Get the input value from the event
    const value = event.target.value;

    // Check if the input value is a number and doesn't exceed 10 digits
    if (value === "" || (value.match(/^\d+$/) && value.length <= 10)) {
      // Update the phoneNumber state
      setPhoneNumber(value);
    }
  };
  const sendOtp = () => {
    setLoading(true)
    const data = {
      operatorId: "orange_egypt",
       userId: "20"+phoneNumber,
     // userId: "917428689305",
    };
    POST(API_SWIP_URLS.SendOtp, data)
      .then((res) => {
        console.log(res);
        setLoading(false)
        window.localStorage.setItem("user-msisdn", "20" + phoneNumber);
        if (res.status !== "failure") {
          navigate("/otp");
        }else{
          navigate("/home")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isButtonDisabled = phoneNumber.length < 10;
  return (
    <div className="main h-screen">
      <Header text="login2"/>
      {loading?<Loader />:""}
      <div className="image-container ">
        <img src={Login} alt="login" className="login-image" />
        <div className={`promo-text text-[#440084] text-semibold ${getLang==="ar"?"arabic-lang":""}`}>
        العب و اكسب 
        </div>
      </div>
      <div className="form-container z-20">
        <h2 className={`text-[#ebd642] mb-8 text-xl font-semibold ${getLang==="ar"?"arabic-lang":""}`}>
        تأكيد رقم الهاتف
        </h2>
        <div className="input-group">
          <span className="country-code bg-[#9876ff] text-white">+20</span>
          <input
            type="tel"
            className="phone-input"
            placeholder="ادخل الرقم"
            onChange={handleInputChange}
            value={phoneNumber}
            maxLength={10}
          />
        </div>
        <button
          className={`submit-button ${getLang==="ar"?"arabic-lang":""}`}
          disabled={isButtonDisabled}
          onClick={sendOtp}
        >
          التالي
        </button>
      </div>
    </div>
  );
}

export default LoginScreen;
